import api from "./api";

const AdminService = {

    createCategories(payload) {
        return api.post(`/category/create`, payload)
    },

    getUserinfo() {
        return api.get("/auth/me", { headers: { hideLoader: true } });
    },

    getCategories() {
        return api.get("/category", { headers: { hideLoader: true } });
    },

    getCategory(id) {
        return api.get(`/category/${id}`);
    },

    updateCategory(payload) {
        return api.patch(`/category`, payload)
    },

    createAgents(payload) {
        return api.post(`/user/create-agent`, payload)
    },

    getAgent(id) {
        return api.get(`/user/agent/${id}`);
    },

    getAgents() {
        return api.get(`/user/agents`);
    },

    updateAgent(payload) {
        return api.patch(`/user/agent`, payload)
    },

    getVerifications() {
        return api.get("/flapper-verification");
    },

    approveVerification(payload) {
        return api.patch(`/flapper-verification`, payload)
    },

    getSupport(id) {
        return api.get(`/support/${id}`);
    },

    getSupports() {
        return api.get(`/support`);
    },

    getReportedNews() {
        return api.get(`/report`);
    },

    getUsers() {
        return api.get(`/user/all`);
    },

    markInactive(payload) {
        return api.patch(`/user`, payload)
    },

    getNews() {
        return api.get(`/news/all`);
    },

    getFeed() {
        return api.get(`/feed/all`);
    },

    markInactiveNews(payload) {
        return api.patch(`/news`, payload)
    },

    markInactiveFeed(payload) {
        return api.patch(`/feed`, payload)
    },

    createAdmin(payload) {
        return api.post(`/auth/v1/admin-register`, payload)
    },

    getAdmins() {
        return api.get("/admin", { headers: { hideLoader: true } });
    },

    getAdmin(id) {
        return api.get(`/admin/${id}`);
    },

    deleteAdmin(id) {
        return api.delete(`/admin/${id}`);
    },

    updateAdmin(payload) {
        return api.patch(`/admin`, payload)
    },

}

export default AdminService;