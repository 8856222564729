import { lazy } from "react";
import { Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import TokenService from "../Services/token.service";

// Auth Module
const AuthLayout = lazy(() => import('../Modules/Auth/Auth'));
const LoginLayout = lazy(() => import('../Modules/Auth/Login/Login'));

// Admin Module
const AdminLayout = lazy(() => import('../Modules/Admin/Admin'));
const CategoriesLayout = lazy(() => import('../Modules/Admin/Categories/Categories'));
const AddCategoryLayout = lazy(() => import('../Modules/Admin/Categories/AddCategory/AddCategory'));
const FlapperVerificationsLayout = lazy(() => import('../Modules/Admin/FlapperVerifications/FlapperVerifications'));
const SupportListLayout = lazy(() => import('../Modules/Admin/SupportList/SupportList'));
const ReportedNewsListLayout = lazy(() => import('../Modules/Admin/ReportedNewsList/ReportedNewsList'));
const UserListLayout = lazy(() => import('../Modules/Admin/UserList/UserList'));
const NewsListLayout = lazy(() => import('../Modules/Admin/NewsList/NewsList'));
const FeedListLayout = lazy(() => import('../Modules/Admin/FeedList/FeedList'));
const MembersLayout = lazy(() => import('../Modules/Admin/Members/MemberList'));
const AddMemberLayout = lazy(() => import('../Modules/Admin/Members/AddMember/AddMember'));

export const routes = ({ actions, role }) => (
    [
        {
            path: '/',
            element:  !TokenService.getLocalAccessToken() ? <Navigate to="/auth/login" /> : <Navigate to="/admin" />
        },
        {
            path: '/auth',
            element: (<AuthLayout />),
            children: [
                {
                    index: true,
                    element: <Navigate to="/auth/login" />
                },
                {
                    path: 'login',
                    element: <LoginLayout />
                }
            ]
        },
        {
            path: '/admin',
            element: (<PrivateRoute actionName="Admin View" actions={actions} component={AdminLayout} />),
            children: [
                {
                    path: 'members',
                    element: <PrivateRoute actionName="Manage Admins" actions={actions} component={MembersLayout} />
                },
                {
                    path: 'add-member',
                    element: <PrivateRoute actionName="Manage Admins" actions={actions} component={AddMemberLayout} />
                },
                {
                    path: 'categories',
                    element: <PrivateRoute actionName="Manage Categories" actions={actions} component={CategoriesLayout} />
                },
                {
                    path: 'support-list',
                    element: <PrivateRoute actionName="Manage Support" actions={actions} component={SupportListLayout} />
                },
                {
                    path: 'reported-news-list',
                    element: <PrivateRoute actionName="Manage Reported News" actions={actions} component={ReportedNewsListLayout} />
                },
                {
                    path: 'user-list',
                    element: <PrivateRoute actionName="Manage Users" actions={actions} component={UserListLayout} />
                },
                {
                    path: 'news-list',
                    element: <PrivateRoute actionName="Manage News" actions={actions} component={NewsListLayout} />
                },
                {
                    path: 'feed-list',
                    element: <PrivateRoute actionName="Manage Feed" actions={actions} component={FeedListLayout} />
                },
                {
                    path: 'verifications',
                    element: <PrivateRoute actionName="Manage Flapper Verifications" actions={actions} component={FlapperVerificationsLayout} />
                },
                {
                    path: 'add-category',
                    element: <PrivateRoute actionName="Manage Categories" actions={actions} component={AddCategoryLayout} />
                },

            ]
        },
        {
            path: '*',
            element: <Navigate to="/" />
        }
    ]
)