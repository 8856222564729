import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import TokenService from '../Services/token.service';
import { addActions } from '../Store/features/user.slice';
import AdminService from '../Services/admin.service';

const PrivateRoute = ({ component: Component, actionName, actions, ...rest }) => {
    const dispatch = useDispatch();

    const currentUser = TokenService.getLocalAccessToken();
    console.log(currentUser, actions)
    useEffect(() => {
        if (currentUser && !actions.length) {
            userinfo()
        }
    }, [])

    const userinfo = async () => {
        try {
            const res = await AdminService.getUserinfo();
            if (res?.data) {
                dispatch(addActions(res?.data));
            }
        } catch (error) {
            console.log(error)
        }
    }
    if (actions.length && !actions.includes(actionName)) {
        console.log(currentUser, 'actions')
        return <Navigate to={{ pathname: '/auth/login' }} />
    } else {
        return (<Component {...rest} />)
    }
}

export default PrivateRoute;